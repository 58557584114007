import { Inject, Injectable } from '@angular/core';
import { WA_LOCAL_STORAGE } from '@ng-web-apis/common';
import { decodeToken, isTokenExpired } from '../util/jwtToken';

@Injectable({ providedIn: 'root' })
export class SimplePermissionService {
  constructor(@Inject(WA_LOCAL_STORAGE) readonly storage: Storage) {}
  static tokenData: any = [];
  /**
   * Checks if the user has at least one of the specified roles.
   *
   * This function retrieves the access token from storage, decodes it if necessary,
   * and checks if any of the provided roles are included in the user's roles.
   *
   * @param roles - An array of role strings to check against the user's roles.
   * @returns A boolean indicating whether the user has at least one of the specified roles.
   *          Returns true if the user has one or more of the specified roles, false otherwise.
   */
  public hasOneOfRoles = (roles: string[]) => {
    const token = this.storage.getItem('access_token') ?? '';
    const tokenData =
      SimplePermissionService.tokenData.length === 0
        ? decodeToken(token)
        : SimplePermissionService.tokenData;
    if (tokenData && tokenData.meta && tokenData.meta.roles) {
      return roles.some((role) => tokenData.meta.roles.includes(role));
    } else {
      return false;
    }
  };
}
